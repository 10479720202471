import React from "react";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
const ZCircularButton = ({ circleProps }) => {
  const iconPlacements = {
    xs: "p-1",
    sm: "p-1.5",
    md: "p-2",
    lg: "p-2",
    xl: "p-3",
  };
  const varients = {
    primary: "bg-indigo-600 border-transparent",
    secondary: "bg-indigo-100 border-transparent",
    plain: "bg-white border-gray-300",
  };
  const sizes = {
    xs: "h-6 w-6 p-1.5",
    sm: "h-8 w-8 p-1.5",
    md: "h-10 w-10 p-1.5",
    lg: "h-14 w-14 p-1.5",
    xl: "h-16 w-16 p-1.5",
  };
  const iconBg = {
    primary: "text-white",
    secondary: "text-indigo-700",
    plain: "text-gray-700",
  };

  return (
    <button
      type="button"
      className={`${sizes[circleProps.size.default]} ${
        varients[circleProps.varient.default]
      } 
     ${
       circleProps.varient.default == "plain"
         ? "hover:bg-gray-50"
         : circleProps.varient.default == "primary"
         ? "hover:bg-indigo-700"
         : "hover:bg-indigo-300"
     }
      inline-flex capitalize items-center justify-center border rounded-full font-medium shadow-sm focus:outline-none`}
    >
      {circleProps.icon.default ? (
        <EnvelopeIcon
          className={` ${
            iconBg[circleProps.iconBg.default]
          }`}
        />
      ) : (
        ""
      )}
    </button>
  );
};
export default ZCircularButton;
