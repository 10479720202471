import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import ZButton from "./ZButton";
import ZCircularButton from "./ZCircularButton";
import ListBox from "../../shared/ListBox";
import { Switch } from "@headlessui/react";
const btnprops = {
  text: {
    type: String,
    default: "button text",
  },
  size: {
    type: String,
    default: "md", //xs | sm | md | lg | xl
  },
  varient: {
    type: String,
    default: "primary", // plain | primary | secondary
  },
  icon: {
    type: String,
    default: "EnvelopeIcon",
  },
  iconPlacement: {
    type: String,
    default: "prefix", //prefix | suffix
  },
  isRoundButton: {
    type: Boolean,
    default: true,
  },
};
const circularProps = {
  size: {
    type: String,
    default: "md", //xs | sm | md | lg | xl
    sizes: ["xs", "sm", "md", "lg", "xl"],
    label: "Icon Size",
  },
  varient: {
    type: String,
    default: "primary", // plain | primary | secondary
    varients: ["primary", "secondary", "plain"],
    label: "Varient",
  },
  icon: {
    type: String,
    default: "EnvelopeIcon",
  },
  iconBg: {
    type: String,
    default: "primary", //xs | sm | md | lg | xl
    iconBgs: ["primary", "secondary", "plain"],
    label: "Icon BG",
  },
};

const sizes = ["xs", "sm", "md", "lg", "xl"];

const variants = ["primary", "secondary", "", "lg", "xl"];

const buttonProps = {
  type: {
    default: "Single Button",
    types: ["Single Button", "Button Group", "Select Button"],
    label: "Button Type",
  },
  buttonLength: {
    default: 3,
    buttonLengths: [2, 3, 4, 5, 6],
    label: "Button Length",
  },
  buttonGroupData: [],
  dropdownButtonData: [],
  dropdownVarient:{
    default: "primary", // plain | primary | secondary
    dropdownVarients: ["primary", "secondary", "plain"],
    label: "Dropdown Varient"
  },
  text: {
    
    default: "button text",
  },
  size: {
    
    default: "md", //xs | sm | md | lg | xl
    sizes: ["xs", "sm", "md", "lg", "xl"],
    label: "Text Size",
  },
  varient: {
    default: "primary", // plain | primary | secondary
    varients: ["primary", "secondary", "plain"],
    label: "Varient",
  },
  isIcon: {
    default: false,
    label: "Icon",
  },
  iconPlacement: {
    default: "prefix", //prefix | suffix
    iconPlacements: ["prefix", "suffix"],
    label: "Icon Placement",
  },
  prefixIconPlacement: {
    default: "md", //prefix | suffix
    prefixIconPlacements: ["xs", "sm", "md", "lg", "xl"],
    label: "Prefix Icon Placement",
  },
  suffixIconPlacement: {
    default: "md", //prefix | suffix
    suffixIconPlacements: ["xs", "sm", "md", "lg", "xl"],
    label: "Suffix Icon Placement",
  },
  isRoundButton: {
    default: true,
    label: "Round Button",
  },
  iconBg: {
    default: "primary", //xs | sm | md | lg | xl
    iconBgs: ["primary", "secondary", "plain"],
    label: "Icon BG",
  },
  tooltipPosition: {
    default: "Top Left", //xs | sm | md | lg | xl
    tooltipPositions: ["Top Left", "Top Right", "Bottom Left", "Bottom Right"],
    label: "Tooltip Position",
  },
  borderVariant: {
    default: "primary", //xs | sm | md | lg | xl
    borderVariants: ["primary", "secondary", "plain"],
    label: "Border Variant",
  },
  xPadding: {
    default: "md", //xs | sm | md | lg | xl
    xPaddings: ["xs", "sm", "md", "lg", "xl"],
    label: "X padding",
  },
  yPadding: {
    default: "md", //xs | sm | md | lg | xl
    yPaddings: ["xs", "sm", "md", "lg", "xl"],
    label: "Y padding",
  },
  borderRadius: {
    default: "md", //xs | sm | md | lg | xl
    borderRadii: ["xs", "sm", "md", "lg", "xl"],
    label: "Border Radius",
  },
  borderWidth: {
    default: "md", //xs | sm | md | lg | xl
    borderWidths: ["xs", "sm", "md", "lg", "xl"],
    label: "Border Width",
  },
  iconData: {
    default: "New Add", //xs | sm | md | lg | xl
    iconDatas: [
      "New Add",
      "Navigate",
      "Quick Filter",
      "Filter",
      "Quick Sort",
      "Sort",
      "Show Hide",
      "Pop Up",
      "Search",
      "Expand",
      "Layout",
      "Download",
      "Down Arrow"
    ],
    label: "Icon List",
  },
  isBorder: {
    default: true,
    label: "Border",
  },
  isGradient: {
    default: true,
    label: "Gradient",
  },
  bgGradient: {
    default: "primary", //xs | sm | md | lg | xl
    bgGradients: ["primary", "secondary", "plain"],
    label: "BG Gradient",
  },
};

const ZParentButton = (props) => {
  const [open, setOpen] = useState(false);
  const [openCircular, setOpenCircular] = useState(
    props?.open ? props?.open : false
  );
  const [title, setTitle] = useState(
    props?.title ? props?.title : "Button Configuration"
  );
  const overlay = props.overlay ? props.overlay : false;
  const [buttonPropsData, setButtonProps] = useState(buttonProps);
  const [circularPropsData, setCircularPropsData] = useState(circularProps);
  const dropdownChangeEvent = (e, type) => {
    setButtonProps((latest) => {
      const data = {
        ...latest,
        [type]: {
          ...latest?.[type],
          default: e,
        },
      };
      return data;
    });
  };

  const btnGroupChangeEvent = (value, type, index) => {
    setButtonProps((latest) => {
      const newGroupData = buttonPropsData.buttonGroupData.map(
        (data, indexInner) => {
          if (index == indexInner) {
            return {
              ...data,
              [type]: {
                ...data?.[type],
                default: value,
              },
            };
          } else return data;
        }
      );

      console.log(newGroupData);
      const returnData = {
        ...latest,
        buttonGroupData: newGroupData,
      };
      console.log(returnData);
      return returnData;
    });
  };

  const dropdownBtnChangeEvent =(value, type, index)=>{
    console.log(buttonPropsData);
    console.log(value,type,index);
    setButtonProps((latest) => {
      const newDropdownData = buttonPropsData.dropdownButtonData.map(
        (data, indexInner) => {
          if (index === indexInner) {
            return {
              ...data,
              [type]: {
                ...data?.[type],
                default: value,
              },
            };
          } else return data;
        }
      );

      console.log(newDropdownData);
      const returnData = {
        ...latest,
        dropdownButtonData: newDropdownData,
      };
      console.log(returnData);
      return returnData;
    });
}

  useEffect(() => {
    setButtonProps((latest) => {
      if (buttonPropsData.type.default === "Button Group") {
        const array = [];

        for (
          let index = 1;
          index <= buttonPropsData.buttonLength.default;
          index++
        ) {
          const element = {
            text: { default: "", label: "" },
            icon: {
              default: "New Add",
              label: `Icon List ${index}`,
            },
          };

          array.push(element);
        }

        return { ...latest, buttonGroupData: array };
      } else if (buttonPropsData.type.default === "Select Button") {
        const array = [];

        for (
          let index = 1;
          index <= buttonPropsData.buttonLength.default;
          index++
        ) {
          const element = { text: { default: "", label: "" } };
          array.push(element);
        }

        return { ...latest, dropdownButtonData: array };
      } else return latest;
    });
  }, [buttonPropsData.buttonLength.default, buttonPropsData.type.default]);

  const dropdownChangeCircularEvent = (e, type) => {
    setCircularPropsData((latest) => {
      const data = {
        ...latest,
        [type]: {
          ...latest?.[type],
          default: e,
        },
      };
      return data;
    });
  };

  const openButtonConfig = () => {
    setOpen(true);
  };
  const openCircularConfig = () => {
    setOpenCircular(true);
  };
  console.log(buttonPropsData);
  return (
    <>
      <div className="flex items-center mt-8 w-1/2 justify-evenly">
        <div className="flex items-center flex-col gap-6">
          <div>
            <button
              type="button"
              // disabled={commonActionConfig && !commonActionConfig.isEditPermission}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  "
              onClick={openButtonConfig}
            >
              <Icon
                className="text-xl"
                icon={"material-symbols:settings"}
              ></Icon>
            </button>
          </div>
          <div>
            <ZButton props={buttonPropsData}></ZButton>
          </div>
        </div>
        <div className="flex items-center flex-col gap-6">
          <div>
            <button
              type="button"
              // disabled={commonActionConfig && !commonActionConfig.isEditPermission}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  "
              onClick={openCircularConfig}
            >
              <Icon
                className="text-xl"
                icon={"material-symbols:settings"}
              ></Icon>
            </button>
          </div>
          <div>
            <ZCircularButton circleProps={circularPropsData}></ZCircularButton>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div
            className={`fixed ${
              overlay
                ? "inset-0 bg-gray-300 bg-opacity-75 transition-opacit"
                : ""
            }`}
          />
          <div className=" inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {title}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hmc-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <Icon
                                  icon="ic:twotone-close"
                                  className="h-6 w-6"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                          {/* Your content */}
                          <div className="grid grid-cols-2 place-content-center		 gap-4 w-full">
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "type")
                                }
                                dropdownList={buttonPropsData.type.types}
                                selected={buttonPropsData.type.default}
                                label={buttonPropsData.type.label}
                              ></ListBox>
                            </div>

                            {(buttonPropsData.type.default === "Button Group" ||
                              buttonPropsData.type.default ===
                                "Select Button") && (
                              <div>
                                <ListBox
                                  dropdownChangeEvent={(e) =>
                                    dropdownChangeEvent(e, "buttonLength")
                                  }
                                  dropdownList={
                                    buttonPropsData.buttonLength.buttonLengths
                                  }
                                  selected={
                                    buttonPropsData.buttonLength.default
                                  }
                                  label={buttonPropsData.buttonLength.label}
                                ></ListBox>
                              </div>
                            )}

                            {(buttonPropsData.type.default ===
                              "Single Button" ||
                              buttonPropsData.type.default ===
                                "Select Button") && (
                              <div>
                                <label
                                  htmlFor="first-name"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Button Text
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="first-name"
                                    value={buttonPropsData.text.default}
                                    onChange={(e) =>
                                      dropdownChangeEvent(
                                        e.target.value,
                                        "text"
                                      )
                                    }
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            )}

                            {buttonPropsData.type.default === "Button Group" &&
                              buttonPropsData.buttonGroupData.map(
                                (data, index) => {
                                  return (
                                    <div
                                      className="grid grid-cols-2 gap-4 col-span-2  "
                                      key={index}
                                    >
                                      <div>
                                        <label
                                          htmlFor="first-name"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Button Text {index + 1}
                                        </label>
                                        <div className="mt-2">
                                          <input
                                            type="text"
                                            name="first-name"
                                            value={data.text.default}
                                            onChange={(e) =>
                                              btnGroupChangeEvent(
                                                e.target.value,
                                                "text",
                                                index
                                              )
                                            }
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          />
                                        </div>
                                      </div>

                                      <div>
                                        <ListBox
                                          dropdownChangeEvent={(e) =>
                                            btnGroupChangeEvent(
                                              e,
                                              "icon",
                                              index
                                            )
                                          }
                                          dropdownList={
                                            buttonPropsData.iconData.iconDatas
                                          }
                                          selected={data.icon.default}
                                          label={data.icon.label}
                                        ></ListBox>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                            {buttonPropsData.type.default === "Select Button" &&
                              buttonPropsData.dropdownButtonData.map(
                                (data, index) => {
                                  return (
                                    <div
                                      
                                      key={index}
                                    >
                                      <div>
                                        <label
                                          htmlFor="first-name"
                                          className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                          Dropdown Button Text {index + 1}
                                        </label>
                                        <div className="mt-2">
                                          <input
                                            type="text"
                                            name="first-name"
                                            value={data.text.default}
                                            onChange={(e) =>
                                              dropdownBtnChangeEvent(
                                                e.target.value,
                                                "text",
                                                index
                                              )
                                            }
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )                        
                            }


                            {buttonPropsData.type.default === "Select Button" &&
                                <div>
                                <ListBox
                                  dropdownChangeEvent={(e) =>
                                    dropdownChangeEvent(e, "dropdownVarient")
                                  }
                                  dropdownList={buttonPropsData.dropdownVarient.dropdownVarients}
                                  selected={buttonPropsData.dropdownVarient.default}
                                  label={buttonPropsData.dropdownVarient.label}
                                ></ListBox>
                              </div>

                            }

                            

                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "varient")
                                }
                                dropdownList={buttonPropsData.varient.varients}
                                selected={buttonPropsData.varient.default}
                                label={buttonPropsData.varient.label}
                              ></ListBox>
                            </div>


                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "size")
                                }
                                dropdownList={buttonPropsData.size.sizes}
                                selected={buttonPropsData.size.default}
                                label={buttonPropsData.size.label}
                              ></ListBox>
                            </div>

                            <div className="flex flex-col justify-between">
                              <div>
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                  htmlFor=""
                                >
                                  {buttonPropsData.isGradient.label}
                                </label>
                              </div>

                              <div>
                                <Switch
                                  checked={buttonPropsData.isGradient.default}
                                  onChange={(e) =>
                                    dropdownChangeEvent(e, "isGradient")
                                  }
                                  className={`${
                                    buttonPropsData.isGradient.default
                                      ? "bg-indigo-600"
                                      : "bg-gray-200"
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${
                                      buttonPropsData.isGradient.default
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "bgGradient")
                                }
                                dropdownList={
                                  buttonPropsData.bgGradient.bgGradients
                                }
                                selected={buttonPropsData.bgGradient.default}
                                label={buttonPropsData.bgGradient.label}
                              ></ListBox>
                            </div>
                            <div className="flex flex-col justify-between">
                              <div>
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                  htmlFor=""
                                >
                                  {buttonPropsData.isRoundButton.label}
                                </label>
                              </div>

                              <div>
                                <Switch
                                  checked={
                                    buttonPropsData.isRoundButton.default
                                  }
                                  onChange={(e) =>
                                    dropdownChangeEvent(e, "isRoundButton")
                                  }
                                  className={`${
                                    buttonPropsData.isRoundButton.default
                                      ? "bg-indigo-600"
                                      : "bg-gray-200"
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${
                                      buttonPropsData.isRoundButton.default
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "borderRadius")
                                }
                                dropdownList={
                                  buttonPropsData.borderRadius.borderRadii
                                }
                                selected={buttonPropsData.borderRadius.default}
                                label={buttonPropsData.borderRadius.label}
                              ></ListBox>
                            </div>

                            <div className="flex flex-col justify-between">
                              <div>
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                  htmlFor=""
                                >
                                  {buttonPropsData.isIcon.label}
                                </label>
                              </div>

                              <div>
                                <Switch
                                  checked={buttonPropsData.isIcon.default}
                                  onChange={(e) =>
                                    dropdownChangeEvent(e, "isIcon")
                                  }
                                  className={`${
                                    buttonPropsData.isIcon.default
                                      ? "bg-indigo-600"
                                      : "bg-gray-200"
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${
                                      buttonPropsData.isIcon.default
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                            {(buttonPropsData.type.default ===
                              "Single Button" || buttonPropsData.type.default ===
                              "Select Button") && (
                              <div>
                                <ListBox
                                  dropdownChangeEvent={(e) =>
                                    dropdownChangeEvent(e, "iconData")
                                  }
                                  dropdownList={
                                    buttonPropsData.iconData.iconDatas
                                  }
                                  selected={buttonPropsData.iconData.default}
                                  label={buttonPropsData.iconData.label}
                                ></ListBox>
                              </div>
                            )}

                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "iconPlacement")
                                }
                                dropdownList={
                                  buttonPropsData.iconPlacement.iconPlacements
                                }
                                selected={buttonPropsData.iconPlacement.default}
                                label={buttonPropsData.iconPlacement.label}
                              ></ListBox>
                            </div>
                            {/* <div>
                              {buttonPropsData.iconPlacement.default ===
                                "prefix" && (
                                <ListBox
                                  dropdownChangeEvent={(e) =>
                                    dropdownChangeEvent(
                                      e,
                                      "prefixIconPlacement"
                                    )
                                  }
                                  dropdownList={
                                    buttonPropsData.prefixIconPlacement
                                      .prefixIconPlacements
                                  }
                                  selected={
                                    buttonPropsData.prefixIconPlacement.default
                                  }
                                  label={
                                    buttonPropsData.prefixIconPlacement.label
                                  }
                                ></ListBox>
                              )}
                              {buttonPropsData.iconPlacement.default ===
                                "suffix" && (
                                <ListBox
                                  dropdownChangeEvent={(e) =>
                                    dropdownChangeEvent(
                                      e,
                                      "suffixIconPlacement"
                                    )
                                  }
                                  dropdownList={
                                    buttonPropsData.suffixIconPlacement
                                      .suffixIconPlacements
                                  }
                                  selected={
                                    buttonPropsData.suffixIconPlacement.default
                                  }
                                  label={
                                    buttonPropsData.suffixIconPlacement.label
                                  }
                                ></ListBox>
                              )}
                            </div> */}
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "iconBg")
                                }
                                dropdownList={buttonPropsData.iconBg.iconBgs}
                                selected={buttonPropsData.iconBg.default}
                                label={buttonPropsData.iconBg.label}
                              ></ListBox>
                            </div>

                            <div className="flex flex-col justify-between">
                              <div>
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                  htmlFor=""
                                >
                                  {buttonPropsData.isBorder.label}
                                </label>
                              </div>

                              <div>
                                <Switch
                                  checked={buttonPropsData.isBorder.default}
                                  onChange={(e) =>
                                    dropdownChangeEvent(e, "isBorder")
                                  }
                                  className={`${
                                    buttonPropsData.isBorder.default
                                      ? "bg-indigo-600"
                                      : "bg-gray-200"
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${
                                      buttonPropsData.isBorder.default
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>

                            {/* <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "tooltipPosition")
                                }
                                dropdownList={buttonPropsData.tooltipPosition.tooltipPositions}
                                selected={buttonPropsData.tooltipPosition.default}
                                label={buttonPropsData.tooltipPosition.label}
                              ></ListBox>
                            </div> */}

                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "borderVariant")
                                }
                                dropdownList={
                                  buttonPropsData.borderVariant.borderVariants
                                }
                                selected={buttonPropsData.borderVariant.default}
                                label={buttonPropsData.borderVariant.label}
                              ></ListBox>
                            </div>

                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "borderWidth")
                                }
                                dropdownList={
                                  buttonPropsData.borderWidth.borderWidths
                                }
                                selected={buttonPropsData.borderWidth.default}
                                label={buttonPropsData.borderWidth.label}
                              ></ListBox>
                            </div>

                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "xPadding")
                                }
                                dropdownList={
                                  buttonPropsData.xPadding.xPaddings
                                }
                                selected={buttonPropsData.xPadding.default}
                                label={buttonPropsData.xPadding.label}
                              ></ListBox>
                            </div>

                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeEvent(e, "yPadding")
                                }
                                dropdownList={
                                  buttonPropsData.yPadding.yPaddings
                                }
                                selected={buttonPropsData.yPadding.default}
                                label={buttonPropsData.yPadding.label}
                              ></ListBox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openCircular} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenCircular}>
          <div
            className={`fixed ${
              overlay
                ? "inset-0 bg-gray-300 bg-opacity-75 transition-opacit"
                : ""
            }`}
          />
          <div className=" inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {title}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hmc-500"
                                onClick={() => setOpenCircular(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <Icon
                                  icon="ic:twotone-close"
                                  className="h-6 w-6"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                          {/* Your content */}
                          <div className="grid grid-cols-2 place-content-center		 gap-4 w-full">
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeCircularEvent(e, "size")
                                }
                                dropdownList={circularPropsData.size.sizes}
                                selected={circularPropsData.size.default}
                                label={circularPropsData.size.label}
                              ></ListBox>
                            </div>
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeCircularEvent(e, "varient")
                                }
                                dropdownList={
                                  circularPropsData.varient.varients
                                }
                                selected={circularPropsData.varient.default}
                                label={circularPropsData.varient.label}
                              ></ListBox>
                            </div>
                            <div>
                              <ListBox
                                dropdownChangeEvent={(e) =>
                                  dropdownChangeCircularEvent(e, "iconBg")
                                }
                                dropdownList={circularPropsData.iconBg.iconBgs}
                                selected={circularPropsData.iconBg.default}
                                label={circularPropsData.iconBg.label}
                              ></ListBox>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-4 inline-flex justify-center rounded-md bg-hmc-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-hmc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hmc-500"
                        >
                          Save
                        </button>
                      </div> */}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default ZParentButton;
