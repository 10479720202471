import { React, useEffect, useState } from "react";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";

const ZButton = ({ props }) => {
  const size = "md";
  const varient = "plain";

  const sizes = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
    xl: "text-xl",
  };
  const varients = {
    primary: "bg-indigo-600 text-white border-transparent",
    secondary: "bg-indigo-100 text-indigo-700 border-transparent",
    plain: "bg-white text-gray-700 border-gray-300",
  };
  const prefixIconPlacement = {
    xs: "-ml-0.5 mr-2 h-4 w-4",
    sm: "-ml-1 mr-2 h-5 w-5",
    md: "-ml-1 mr-3 h-5 w-5",
    lg: "-ml-1 mr-3 h-5 w-5",
    xl: "-ml-1 mr-3 h-5 w-5",
  };
  const suffixIconPlacement = {
    xs: "ml-2 -mr-0.5 h-4 w-4",
    sm: "ml-2 -mr-1 h-5 w-5",
    md: "ml-3 -mr-1 h-5 w-5",
    lg: "ml-3 -mr-1 h-5 w-5",
    xl: "ml-3 -mr-1 h-5 w-5",
  };
  const iconBg = {
    primary: "text-white",
    secondary: "text-indigo-700",
    plain: "text-gray-700",
  };

  const tooltipPositions = {
    "Top Left": "bottom-full right-full",
    "Top Right": "bottom-full left-full",
    "Bottom Left": "top-full right-full",
    "Bottom Right": "top-full left-full",
  };

  const borderVariants = {
    primary: "border-2 border border-indigo-500",
    secondary: "border-2 border border-rose-500",
    plain: "border-2 border border-gray-500",
  };

  const xPaddings = {
    xs: "px-3",
    sm: "px-3.5",
    md: "px-4",
    lg: "px-5",
    xl: "px-6",
  };

  const yPaddings = {
    xs: "py-1.5",
    sm: "py-2",
    md: "py-2.5",
    lg: "py-3",
    xl: "py-3.5",
  };

  const borderRadii = {
    xs: "rounded-md",
    sm: "rounded-lg",
    md: "rounded-xl",
    lg: "rounded-2xl",
    xl: "rounded-full",
  };

  const iconDatas = {
    "New Add": "material-symbols:add",
    "Navigate": "ion:navigate",
    "Quick Filter": "mi:filter",
    "Filter": "mdi:filter",
    "Quick Sort": "mdi:sort",
    "Sort": "basil:sort-solid",
    "Show Hide": "mdi:hide",
    "Pop Up": "mdi:alpha-i-circle",
    "Search": "material-symbols:search",
    "Expand": "gg:expand",
    "Layout": "ph:layout",
    "Download": "bytesize:download",
    "Down Arrow":"ion:chevron-down-outline"
  };

  const borderWidths = {
    xs: "border",
    sm: "border-2",
    md: "border-4",
    lg: "border-8",
    xl: "border-[10px]",
  };

  const gradientVariants = {
    primary: "bg-gradient-to-r from-cyan-500 to-blue-500",
    secondary: "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500",
    plain: "bg-gradient-to-r from-gray-300 to-blue-600",
  };

  const borderRadiiGroupLeft = {
    xs: "rounded-l-md",
    sm: "rounded-l-lg",
    md: "rounded-l-xl",
    lg: "rounded-l-2xl",
    xl: "rounded-l-full",
  };
  const borderRadiiGroupRight = {
    xs: "rounded-r-md",
    sm: "rounded-r-lg",
    md: "rounded-r-xl",
    lg: "rounded-r-2xl",
    xl: "rounded-r-full",
  };

  const dropdownVariant={
    primary: "text-indigo-700 hover:bg-indigo-200",
    secondary: "text-blue-700 hover:bg-blue-200",
    plain: "text-gray-700 hover:bg-gray-200",
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  console.log(props);

 const [dropdownshow,setDropdownShow]=useState(false)

 const [dropdownFilter,setDropdownFilter]=useState(false)

  
  useEffect(() => {
    setDropdownFilter((latest)=>{
      const filterData=props.dropdownButtonData.filter((data)=>{
        if (data.text.default !== '') {
          return data
        }
      })
      if (filterData.length === 0) {
        setDropdownShow(false)
        return false
      } else {
        return true
      }
    })
  }, [props.dropdownButtonData]);



  useEffect(() => {
    if (props.type.default !== 'Select Button') {
      setDropdownShow(false)
    } 
  }, [props.type]);



  

  const clickGroupBtn = () => {
    console.log("Hai");
  };


  const showHideDropdownEvent = () => {
    if (props.type.default === "Select Button") { 
      if (dropdownFilter === false) {
        setDropdownShow(false)
      }
      else{
        setDropdownShow(!dropdownshow)
      }
    }

  }

  console.log(props);

  return (
    <>
      {(props.text.default !== "" || props.isIcon.default === true) && (
        <>
          <div className="relative group">
            {(props.type.default === "Single Button" ||
              props.type.default === "Select Button") && (
              <button
                onClick={showHideDropdownEvent}
                type="button"
                className={`${
                  !props?.isGradient?.default
                    ? varients[props.varient.default]
                    : null
                } ${
                  props?.isGradient?.default
                    ? gradientVariants[props.bgGradient.default]
                    : null
                } ${xPaddings[props?.xPadding?.default]} ${
                  yPaddings[props?.yPadding?.default]
                } ${
                  props.isBorder.default
                    ? classNames(
                        borderWidths[props.borderWidth.default],
                        borderVariants[props.borderVariant.default]
                      )
                    : null
                }
    
            ${sizes[props.size.default]} ,
        ${
          props.isRoundButton.default
            ? borderRadii[props.borderRadius.default]
            : "rounded-none"
        }
           ${
             props.varient.default == "plain"
               ? "hover:bg-gray-50"
               : props.varient.default == "primary"
               ? "hover:bg-indigo-700"
               : "hover:bg-indigo-300"
           } flex capitalize items-center border font-medium shadow-sm focus:outline-none`}
              >
                <div>
                  {props.isIcon.default &&
                  props.iconPlacement.default === "prefix" ? (
                    <Icon
                      className={`${
                        props.text.default !== "" ? "mr-2" : null
                      } ${iconBg[props.iconBg.default]} `}
                      icon={iconDatas[props.iconData.default]}
                    />
                  ) : (
                    // <EnvelopeIcon
                    //   className={`${prefixIconPlacement[props.size.default]} ${
                    //     iconBg[props.varient.default]
                    //   } `}
                    // />
                    ""
                  )}
                </div>
                <div>{props.text.default}</div>
                <div>
                  {props.isIcon.default &&
                  props.iconPlacement.default === "suffix" ? (
                    <Icon
                      className={`${
                        props.text.default !== "" ? "ml-2" : null
                      } ${iconBg[props.iconBg.default]}  `}
                      icon={iconDatas[props.iconData.default]}
                    />
                  ) : (
                    ""
                  )}{" "}
                </div>
              </button>
            )}
            {
              dropdownshow && 
              <div
              id="dropdown"
              className="z-10 absolute mt-3  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                class="py-2 text-sm  dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {props.dropdownButtonData.map((data) => {
                  return data.text.default !== '' && <li>
                    <button class={`block text-left px-4 py-2 w-full  dark:hover:bg-gray-600 dark:hover:text-white ${dropdownVariant[props.dropdownVarient.default]}`}>
                      {data.text.default}
                    </button>
                  </li>
                })}
              </ul>
            </div>
            }
            
            {props.type.default === "Button Group" && (
              <div className="inline-flex rounded-md shadow-sm" role="group">
                {props.buttonGroupData.map((data, index) => {
                  return (
                    (data.text.default !== "" ||
                      props.isIcon.default === true) && (
                      <button
                        onClick={clickGroupBtn}
                        type="button"
                        className={`${
                          !props?.isGradient?.default
                            ? varients[props.varient.default]
                            : null
                        } ${
                          props?.isGradient?.default
                            ? gradientVariants[props.bgGradient.default]
                            : null
                        } ${xPaddings[props?.xPadding?.default]} ${
                          yPaddings[props?.yPadding?.default]
                        } ${
                          props.isBorder.default
                            ? classNames(
                                borderWidths[props.borderWidth.default],
                                borderVariants[props.borderVariant.default]
                              )
                            : null
                        }
            
                    ${sizes[props.size.default]} ,
                ${
                  props.isRoundButton.default && index === 0
                    ? borderRadiiGroupLeft[props.borderRadius.default]
                    : "rounded-none"
                }
                ${
                  props.isRoundButton.default && 
                  props.buttonGroupData.length === index + 1
                    ? borderRadiiGroupRight[props.borderRadius.default]
                    : "rounded-none"
                }
                   ${
                     props.varient.default == "plain"
                       ? "hover:bg-gray-50"
                       : props.varient.default == "primary"
                       ? "hover:bg-indigo-700"
                       : "hover:bg-indigo-300"
                   } flex capitalize focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700  items-center border font-medium shadow-sm focus:outline-none`}
                      >
                        <div>
                          {props.isIcon.default &&
                          props.iconPlacement.default === "prefix" ? (
                            <Icon
                              className={`${
                                data.text.default !== "" ? "mr-2" : null
                              } ${iconBg[props.iconBg.default]} `}
                              icon={iconDatas[data.icon.default]}
                            />
                          ) : (
                            // <EnvelopeIcon
                            //   className={`${prefixIconPlacement[props.size.default]} ${
                            //     iconBg[props.varient.default]
                            //   } `}
                            // />
                            ""
                          )}
                        </div>
                        <div>{data.text.default}</div>
                        <div>
                          {props.isIcon.default &&
                          props.iconPlacement.default === "suffix" ? (
                            <Icon
                              className={`${
                                data.text.default !== "" ? "ml-2" : null
                              } ${iconBg[props.iconBg.default]}  `}
                              icon={iconDatas[data.icon.default]}
                            />
                          ) : (
                            ""
                          )}{" "}
                        </div>
                      </button>
                    )
                  );
                })}
              </div>
            )}

            {/* <div className={`absolute hidden   rounded-md group-hover:flex text-center text-white text-sm w-auto max-w-[150px]  px-3 py-1 bg-gray-700 ${tooltipPositions[props.tooltipPosition.default]}`}>
                      Tooltip
                </div> */}
          </div>
        </>
      )}
    </>
  );
};
export default ZButton;
