import './App.css';
import ZParentButton from './components/Buttons/ZParentButton';

function App() {
  return (
    <div className="App">
      <ZParentButton></ZParentButton>
    </div>
  );
}

export default App;
